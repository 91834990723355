<template>
  <el-container class="container" ref="container" 
    style1="width:100%;height:100%;margin:0 auto;" :style="'max-width:'+divwidth+'px;'">
    <el-header style="height:90px;">
    <div style="justify-content:space-between;align-items:center;display: flex;
        margin:0px auto;width:80%;margin-top:10px;">
      <el-button  :size="size" plain type="text" :style="selection==0?'color:#409eff':'color:#666'" @click="getOrderList(0)">
        <div style="justify-content:space-between;align-items:center;display: flex;
          flex-direction:column;margin-top:0px;width:70px;">
          <el-badge :value=orderstatus[0] :max="99" class="item">
            <i class="el-icon-s-check" style="font-size:36px;margin-bpttom:5px;"></i>
          </el-badge>未审核
        </div>
      </el-button>
      <el-button  :size="size" plain type="text" :style="selection==1?'color:#409eff':'color:#666'" @click="getOrderList(1)">
        <div style="justify-content:space-between;align-items:center;display: flex;
          flex-direction:column;margin-top:0px;width:70px;">
          <el-badge :value="orderstatus[1]" :max="99" class="item">
            <i class="el-icon-s-cooperation" style="font-size:36px;margin-bpttom:5px;"></i>
          </el-badge>未派送
        </div>
      </el-button>
      <el-button  :size="size" plain type="text" :style="selection==2?'color:#409eff':'color:#666'" @click="getOrderList(2)">
        <div style="justify-content:space-between;align-items:center;display: flex;
          flex-direction:column;margin-top:0px;width:70px;">
          <el-badge :value="orderstatus[2]" :max="99" class="item">
            <i class="el-icon-s-order" style="font-size:36px;margin-bpttom:5px;"></i>
          </el-badge>未完成
        </div>
      </el-button>
      <el-button  :size="size" plain type="text" :style="selection==3?'color:#409eff':'color:#666'" @click="getOrderList(3)">
        <div style="justify-content:space-between;align-items:center;display: flex;
          flex-direction:column;margin-top:0px;width:70px;">
          <el-badge :value="orderstatus[3]" :max="99" class="item">
            <i class="el-icon-s-claim" style="font-size:36px;margin-bpttom:5px;"></i>
          </el-badge>已完成
        </div>
      </el-button>
      
    </div>

    </el-header> 
    <div style="height:5px;background: #efeff4;"></div>
    <el-main ref="elmaincards" style="justify-content: flex-start;display: flex;flex-direction:column;overflow-y: auto;"
      v-if="orderlists.length>0">
    <div v-for="(item,index) in orderlists" :key="index" >
      <el-card style="margin-bottom:15px;background: #f9ffff;" >
        <div class="flex_row_left" style="width:100%;min-width:250px;cursor:pointer;" @click="getOrderDetails(index)" >
          <i class="el-icon-s-promotion" style="font-size:26px;margin-right:10px;" :style="'color:'+item.dcolor"></i>
          <div style="font-weight:550;">预约日期：{{item.cdate}}</div>
          <div class="flex_row_right" style="flex-grow:1">
            <i class="el-icon-arrow-right" style="font-size:24px;"></i>
          </div>
        </div>
        <div class="flex_row_left" style="width:100%;padding-left:32px;line-height:35px;">{{item.Contact}}({{item.m_stats}})</div>
        <div class="subtitle flex_row" style="background:#f6f6f6;">
            <div class="label0" >服务内容</div>
            <div class="label1">瓶/次</div>
            <div class="label2">金额</div>
        </div>
        <div class="subtitle flex_row" style1="background:#f6f6f6;" v-for="(citem,index) in item.details" :key="index" >
            <div class="label0" >{{citem.m_webname}}</div>
            <div class="label1">{{citem.count}}</div>
            <div class="label2">{{citem.m_price}}</div>
        </div>
        <div class="subtitle flex_row" style="background:#d3eefc;" >
            <div class="label0" >小计</div>
            <div class="label1">{{item.count}}</div>
            <div class="label2">{{item.m_price}}</div>
        </div>
      </el-card>
      
    </div>
    </el-main>
    <div style="color:#a0a0a0;margin:30px auto;" v-else>没有所查类别的订单</div>
    <el-drawer
      :visible.sync="showOrderDetails"
      direction="rtl" append-to-body  
      custom-class="drawerorderdetails" title="预约订单详情"
      ref="drawerOrderDetails" :size="isPc?elmaincard:'90%'"
      >
      <!-- <div class="demo-drawer__content" >:modal="false"  :close-on-click-modal="false" :wrapperClosable="false"-->
      <el-container class="container">
        <el-header style="height:45px;padding:0px;">
          <div class="flex_row"  style="justify-content: space-between;color:#666;background-color:#efeff4;">
            <i class="el-icon-arrow-left" @click="nextPre('p')" 
              style="font-size:24px;width:60px;line-height:40px;text-align: center;cursor:pointer;"></i>
            <i class="el-icon-arrow-right" @click="nextPre('n')" 
              style="font-size:24px;width:60px;line-height:40px;text-align: center;cursor:pointer;"></i>
          </div>
        </el-header>
        <el-main style="justify-content:flex-start;display:flex;flex-direction:column;overflow-y:auto;">
          <div v-for="(item,index) in orderdetails" :key="index" >
            <el-card style="margin-bottom:15px;background: #f9ffff;" >
              <div class="flex_row_left" style="width:100%;min-width:250px;" >
                <i class="el-icon-info" style="font-size:26px;margin-right:10px;" 
                :style="'color:'+item.dcolor"></i>
                <div style="font-weight:550;">预约时间：{{item.m_xdtime}}</div>
                <div class="flex_row_right" style="flex-grow:1" v-if="item.cstatus=='未审核'">
                  <i class="el-icon-delete-solid" @click="deleteOrder(item.xsid)" style="font-size:24px;color:#409EFF;cursor:pointer;"></i>
                </div>
              </div>
              <div class="flex_row_left" style="width:100%;padding-left:32px;line-height:35px;">用户名称 : {{item.Contact}}</div>
              <div class="flex_row_left" style="width:100%;padding-left:32px;line-height:35px;">服务地址 : {{item.m_caddress}}</div>
              <div class="flex_row_left" style="width:100%;padding-left:32px;line-height:35px;">服务状态 : {{item.cstatus+' '+item.cztime}}</div>

              <div class="flex_row_left" style="background:#f6f6f6;line-height:35px;">
                  <div class="labe20" style="padding-left:32px;">服务内容</div>
                  <div class="labe21">{{item.yylx}}</div>
                  <div class="labe22">{{item.m_price}}</div>
              </div>
              <div class="flex_row_left" v-if="item.checktype" style="background:#d3eefc;line-height:35px;" @click="showImage(index)" >
                  <div class="label0" style="padding-left:32px;">{{item.checktype}} : {{item.checkresult}}</div>
                  <div class="flex_row_right" style="flex-grow:1" v-if="item.ajdetails.length>0">
                    <span style="color:#a0a0a0;font-size:26rpx">照片</span>
                    <i class="el-icon-arrow-right" style1="font-size:24px;"></i>
                  </div>
              </div>
            </el-card>
          </div>
        </el-main>
      </el-container>
      <el-drawer
        title="安检照片" direction="rtl" 
        :append-to-body="true" :size="isPc?elmaincard+'px':'90%'"
        :visible.sync="showajImage">
        <div style="justify-content:flex-start;display:flex;flex-direction:column;overflow-y:auto;width:100%;height:100%"
        v-for="item in ajImageLists" :key="item" >
            <div style="width:90%;margin:10px auto;overflow-y:hidden;"> 
              <el-image  style1="width:99%;"
            :src="item"
            fit="scale-down"></el-image>
            </div>
        </div>
      </el-drawer>
    </el-drawer>  
  </el-container>
</template>

<script>
/* eslint-disable */
import QRCode from "qrcode";
import sfutil from '../../Api/identity.js';
export default {
  data() {
    return { 
      size: 'medium',//medium,small,mini,
      divwidth:700,
      elmaincard:500,
      isPc:false,
      orderstatus:{},
      selection:-1,
      orderlists:[],
      orderlistsIndex:0,//查明细，上一条下一条
      showOrderDetails:false,//是否显示订单明细
      orderdetails:[],
      ajImageLists:[],//安检照片
      showajImage:false,//是否显示图片
      LoginInfo:[],
      Gaslist:[],
    }
  },
  computed: {
    iSum() {
      let isum=0
      this.Gaslist.forEach((item)=>{
        isum+=item.m_price*item.sl;
      })
      return isum;
    },
    OrderlistRefr(){
      return this.$store.getters.GetRefrshItem("OrderlistRefr");
    },
    tabBarWidth(){
      return this.$store.getters.GettabBarInfo("tabBarWidth");
    }
  },
  watch: {
    OrderlistRefr() {
      console.log("预约订单有变动。。。。");
      this.getorderstatus();
    },
    tabBarWidth(){
      this.initDivSize();
    }
  },
  methods: {
    showImage(index){
      let images=this.orderdetails[index].ajdetails
      if (images.length==0) return
      this.ajImageLists=images.map(item=>'http://image.yh59481717.com/'+item+'.jpg')
      this.showajImage=true;  
    },
    deleteOrder(xsid){
     this.$confirm("真的要取消这个预约订气吗?", "确认操作", {
          confirmButtonText: "确认",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"yhjj",
            lx: "deletegasorder",
            sqlone:xsid
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                this.$message.success(res["message"]);
                let orderdetails=this.orderdetails.filter((item)=>{
                  return item.xsid!=xsid
                })
                if (orderdetails.length==0){
                  this.showOrderDetails=false
                }
                this.orderdetails=orderdetails;
                this.getorderstatus();     
              }else{
                this.$message.error(res["message"]);
              }

            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let divwidth=this.$store.getters.GettabBarInfo("tabBarWidth");
      //tabBarWidth=tabBarWidth
      if (!this.isPc || divwidth<700){
        this.divwidth=divwidth;
      }else{
        this.divwidth=700;
      }
      if (this.$refs.elmaincards){
        this.$nextTick(() => {
          //监听到事件后执行的业务逻辑
          
                     //console.log(element.offsetWidth,element.offsetHeight);       
                 
          
       
        let elmaincard = this.$refs.elmaincards.$el.offsetWidth*0.8
        this.elmaincard = elmaincard<380?380:elmaincard;
        console.log("this.elmaincard",elmaincard,this.elmaincard);
         });
      }
    },
    getorderstatus(){
      let data = {
        lx: "getorderstatus",
        procext:"yhjj"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            let myarr=[res["zd10"],res["zd11"],res["zd12"],res["zd13"]];
            this.orderstatus=myarr.map(item=>{
              if (item=="0") item=''
              return item;
            })
            this.selection=myarr.findIndex(e=>e>0);
            this.getOrderList(this.selection);
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    getOrderList(idx){
      if (idx<0) return
      this.selection=idx;
      let data = {
        lx: "getorderlist",
        sqltwo:idx,
        procext:"yhjj"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            let orderlists=res.records
            let orderds=res.recordc
            if (orderlists.length>0){
              orderlists.forEach(item => {
                item.details=orderds.filter(citem=>{
                  return citem.cdate==item.cdate && citem.khbh==item.khbh
                })
                item.dcolor=(item.cstats=='3'?'#67C23A':(item.cstats=='2'?'#409EFF':(item.cstats=='1'?'##E6A23C':'#F56C6C')));
                item.m_stats=(item.cstats=='3'?'完成':(item.cstats=='2'?'未完成':(item.cstats=='1'?'未派送':'未审核')));
              })
            }
            this.orderlists=orderlists;
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    nextPre(flag){
      let idx=this.orderlistsIndex;
      if (flag=="p" && idx<1){
          this.$message.error("已经是第一条记录");
          return
      }
      if (flag=="n" && idx>=(this.orderlists.length-1)){
          this.$message.error("已经是最后的记录");
          return
      }
      this.getOrderDetails(flag=="n"?idx+1:idx-1)
    },
    getOrderDetails(idx){
      if (idx<0 || idx>=this.orderlists.length) return
      let cdate=this.orderlists[idx].cdate;
      let khbh=this.orderlists[idx].khbh
      this.orderlistsIndex=idx;
      let data = {
        lx: "getorderdetails",
        sqlone:cdate,
        sqltwo:khbh,
        procext:"yhjj"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            let orderdetails=res.records
            let anj=res.recordc
            orderdetails.forEach(item => {
              var ajdetails=[]
              anj.filter(citem=>{
                return citem.xsid==item.xsid
              }).forEach(yitem=>{
                ajdetails.push(yitem.recid)
              })
              item.ajdetails=ajdetails;
              item.dcolor=(item.cstatus=='完成'?'#67C23A':(item.cstatus=='未完成'?'#409EFF':(item.cstatus=='未派送'?'##E6A23C':'#F56C6C')));
            })
            this.orderdetails=orderdetails;
            this.showOrderDetails=true;
            console.log(this.orderdetails)
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
  },
  created() {
    let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    if (logininfo) {
      this.LoginInfo=logininfo;
    }
    this.getorderstatus();
  },
  mounted() {
    window.onresize = () => {
      //this.initDivSize();
    };
    setTimeout(() => {
      this.initDivSize();
    }, 300);
    
  },
}
</script>
<style scoped>
.flex_row_left{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;     /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_row_right{
    display: flex;             
    justify-content: flex-end; 
    align-items: center; 
    flex-direction: row;
}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: center;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.subtitle{
  font-size:16px;
  color:#222222;
  line-height:30px;
  padding-left:10px;
  /* padding-top:5px;
  padding-bottom:5px; */
}
.label0{
  width:50%;
}
.label1{
  width:15%;
  text-align: center;
}
.label2{
  width:35%;
  text-align: center;
}
.labe20{
  width:30%;
}
.labe21{
  width:45%;
  text-align: center;
}
.labe22{
  width:25%;
  text-align: center;
}
.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-form-item {
    margin-bottom: 0px;
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:20%;
  max-width:150px;
  min-width:80px;
}
.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  width: 100px;
  margin-left: 20px;
}
.el-card__body{
  padding:0  !important; 
}

  </style>

